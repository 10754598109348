import PageHeader from '../components/PageHeader';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';

const Services = () => {
  const services = [
    {
      title: "Web Development",
      icon: "fas fa-code",
      description: "Custom web applications and websites built with modern technologies",
      features: [
        "Single-Page & Multi-Page Websites",
        "E-Commerce Solutions",
        "Custom Web Applications",
        "Mobile-Friendly Design",
        "SEO Optimization",
        "Performance & Security",
        "React & Modern JavaScript",
        "Progressive Web Apps"
      ],
      bestFor: "Perfect for: Startups, E-commerce, Service Businesses"
    },
    {
      title: "Data Analytics",
      icon: "fas fa-chart-line",
      description: "Transform your data into actionable business insights",
      features: [
        "Custom BI Dashboards",
        "KPI & Performance Analysis",
        "Automated Reports",
        "Trend Analysis & Forecasting",
        "Healthcare Analytics",
        "Financial Insights",
        "Data Visualization",
        "Business Intelligence"
      ],
      bestFor: "Perfect for: Healthcare, Finance, E-commerce"
    },
    {
      title: "Machine Learning",
      icon: "fas fa-brain",
      description: "AI-powered solutions to automate and optimize your business",
      features: [
        "Customer Behavior Forecasting",
        "Demand Prediction",
        "Risk Assessment",
        "Machine Learning Models",
        "Real-time Analytics",
        "Automated Insights",
        "Pattern Recognition",
        "Process Automation"
      ],
      bestFor: "Perfect for: Retail, Finance, Manufacturing"
    }
  ];

  return (
    <div>
      <PageHeader
        title="Our Services"
        subtitle="Comprehensive Solutions for Your Business Needs"
        backgroundImage="url('https://images.unsplash.com/photo-1486406146926-c627a92ad1ab?auto=format&fit=crop&w=1600&q=80')"
      />

      <div className="container mt-5">
        {/* Services Overview */}
        <div className="row justify-content-center mb-5">
          <div className="col-lg-8 text-center">
            <h2 className="gradient-text mb-4">Transform Your Business</h2>
            <p className="lead">
              At SlydeTech, we help businesses leverage modern technology, data analytics, 
              and web solutions to drive growth. Our expert team delivers solutions that 
              work for you.
            </p>
          </div>
        </div>

        {/* Service Cards */}
        <div className="row mb-5">
          {services.map((service, index) => (
            <div className="col-lg-4 mb-4" key={index}>
              <motion.div 
                className="service-detail-card"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: index * 0.2 }}
                whileHover={{ y: -10 }}
              >
                <div className="service-icon-large">
                  <i className={service.icon}></i>
                </div>
                <h3 className="text-center mb-3">{service.title}</h3>
                <p className="text-center mb-4">{service.description}</p>
                <ul className="features-list">
                  {service.features.map((feature, idx) => (
                    <li key={idx} className="feature-item">
                      <i className="fas fa-check-circle text-success me-2"></i>
                      {feature}
                    </li>
                  ))}
                </ul>
                <p className="text-center mt-4 industry-text">
                  <i className="fas fa-building me-2"></i>
                  {service.bestFor}
                </p>
              </motion.div>
            </div>
          ))}
        </div>

        {/* Process Section */}
        <div className="row justify-content-center mb-5">
          <div className="col-lg-12">
            <div className="service-detail-card">
              <h3 className="gradient-text text-center mb-4">Our Process</h3>
              <div className="row">
                <div className="col-md-4 mb-4">
                  <div className="process-card">
                    <div className="process-icon">
                      <i className="fas fa-lightbulb"></i>
                    </div>
                    <h4>Discovery</h4>
                    <p>Understanding your needs and objectives</p>
                  </div>
                </div>
                <div className="col-md-4 mb-4">
                  <div className="process-card">
                    <div className="process-icon">
                      <i className="fas fa-pencil-ruler"></i>
                    </div>
                    <h4>Design</h4>
                    <p>Creating the perfect solution</p>
                  </div>
                </div>
                <div className="col-md-4 mb-4">
                  <div className="process-card">
                    <div className="process-icon">
                      <i className="fas fa-rocket"></i>
                    </div>
                    <h4>Delivery</h4>
                    <p>Implementing and launching your solution</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Why Choose Us */}
        <div className="row justify-content-center mb-5">
          <div className="col-lg-12">
            <div className="service-detail-card">
              <h3 className="gradient-text text-center mb-4">Why Choose SlydeTech?</h3>
              <div className="row">
                <div className="col-md-4 mb-4">
                  <div className="value-prop-card">
                    <i className="fas fa-star mb-3"></i>
                    <h4>Expert-Led Solutions</h4>
                    <p>Years of experience in data analytics, web development, and AI</p>
                  </div>
                </div>
                <div className="col-md-4 mb-4">
                  <div className="value-prop-card">
                    <i className="fas fa-puzzle-piece mb-3"></i>
                    <h4>Custom-Tailored Approach</h4>
                    <p>Every project is built around your specific business goals</p>
                  </div>
                </div>
                <div className="col-md-4 mb-4">
                  <div className="value-prop-card">
                    <i className="fas fa-hands-helping mb-3"></i>
                    <h4>End-to-End Support</h4>
                    <p>From strategy to implementation & maintenance</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* CTA Section */}
        <div className="row justify-content-center">
          <div className="col-lg-10">
            <div className="cta-section text-center">
              <h2 className="gradient-text mb-4">Ready to Transform Your Business?</h2>
              <p className="lead mb-4">
                Let's discuss how we can help you achieve your goals with our custom solutions.
              </p>
              <Link to="/get-started" className="cta-button">
                Schedule a Free Consultation <i className="fas fa-arrow-right ms-2"></i>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Services; 