import PageHeader from '../components/PageHeader';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';

const About = () => {
  return (
    <div>
      <PageHeader
        title="About SlydeTech"
        subtitle="Where Innovation Meets Business Solutions"
        backgroundImage="url('https://images.unsplash.com/photo-1486406146926-c627a92ad1ab?auto=format&fit=crop&w=1600&q=80')"
      />

      <div className="container mt-5">
        {/* Company Story */}
        <div className="row justify-content-center mb-5">
          <div className="col-lg-8 text-center">
            <motion.h2 
              className="gradient-text mb-4"
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.7 }}
            >
              Not Your Typical Tech Company
            </motion.h2>
            <motion.p 
              className="lead"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.3, duration: 0.7 }}
            >
              SlydeTech emerged from a simple realization: most businesses are drowning in data 
              but starving for insights. We're changing that.
            </motion.p>
            <motion.p
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.5, duration: 0.7 }}
            >
              Built on a foundation of academic excellence and real-world experience, we bridge the gap 
              between cutting-edge innovation and practical business solutions. Our team combines expertise 
              in data science, machine learning, and web development to create digital experiences that 
              transform how businesses operate.
            </motion.p>
          </div>
        </div>

        {/* Mission & Values with animated gradient border */}
        <div className="row mb-5">
          <div className="col-md-6 mb-4">
            <motion.div 
              className="about-card gradient-border"
              initial={{ opacity: 0, x: -50 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ delay: 0.1 }}
              whileHover={{ y: -10, boxShadow: "0 15px 30px rgba(0,0,0,0.3)" }}
            >
              <h3 className="gradient-text">Our Mission</h3>
              <p>
                To democratize advanced technology and data science, making it accessible to businesses 
                of all sizes. We transform complex algorithms and cutting-edge research into practical 
                solutions that drive real business growth.
              </p>
            </motion.div>
          </div>
          <div className="col-md-6 mb-4">
            <motion.div 
              className="about-card gradient-border"
              initial={{ opacity: 0, x: 50 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ delay: 0.2 }}
              whileHover={{ y: -10, boxShadow: "0 15px 30px rgba(0,0,0,0.3)" }}
            >
              <h3 className="gradient-text">Our Principles</h3>
              <ul className="values-list">
                <li><i className="fas fa-brain text-warning me-2"></i> Innovation without the buzzwords</li>
                <li><i className="fas fa-chart-line text-warning me-2"></i> Data-driven, always</li>
                <li><i className="fas fa-rocket text-warning me-2"></i> Speed without sacrificing quality</li>
                <li><i className="fas fa-handshake text-warning me-2"></i> Partnerships, not just projects</li>
              </ul>
            </motion.div>
          </div>
        </div>

        {/* Our Approach with animated counter */}
        <div className="row justify-content-center mb-5">
          <div className="col-lg-10">
            <motion.div 
              className="approach-section"
              initial={{ opacity: 0, y: 30 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.3 }}
            >
              <h3 className="gradient-text text-center mb-4">How We Think Differently</h3>
              <div className="row">
                <div className="col-md-4 mb-4">
                  <motion.div 
                    className="approach-card text-center"
                    whileHover={{ 
                      y: -15, 
                      backgroundColor: "rgba(255, 255, 255, 0.07)",
                      borderColor: "rgba(255, 204, 0, 0.3)"
                    }}
                  >
                    <div className="approach-icon">
                      <i className="fas fa-microscope"></i>
                    </div>
                    <h4>Research-Backed</h4>
                    <p>We apply academic research methodologies to solve business problems others can't crack</p>
                  </motion.div>
                </div>
                <div className="col-md-4 mb-4">
                  <motion.div 
                    className="approach-card text-center"
                    whileHover={{ 
                      y: -15, 
                      backgroundColor: "rgba(255, 255, 255, 0.07)",
                      borderColor: "rgba(255, 204, 0, 0.3)"
                    }}
                  >
                    <div className="approach-icon">
                      <i className="fas fa-code-branch"></i>
                    </div>
                    <h4>Future-Proof</h4>
                    <p>We build solutions that evolve with your business and adapt to changing technologies</p>
                  </motion.div>
                </div>
                <div className="col-md-4 mb-4">
                  <motion.div 
                    className="approach-card text-center"
                    whileHover={{ 
                      y: -15, 
                      backgroundColor: "rgba(255, 255, 255, 0.07)",
                      borderColor: "rgba(255, 204, 0, 0.3)"
                    }}
                  >
                    <div className="approach-icon">
                      <i className="fas fa-bolt"></i>
                    </div>
                    <h4>Results-Focused</h4>
                    <p>We measure success by the tangible business outcomes our solutions deliver</p>
                  </motion.div>
                </div>
              </div>
            </motion.div>
          </div>
        </div>

        {/* CTA Section with particle effect */}
        <div className="row justify-content-center">
          <div className="col-lg-10">
            <motion.div 
              className="cta-section text-center particles-container"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.7 }}
            >
              <div className="particles"></div>
              <h2 className="gradient-text mb-4">Ready To Revolutionize Your Business?</h2>
              <p className="lead mb-4">
                Let's combine your industry expertise with our technical excellence to create something extraordinary.
              </p>
              <motion.div
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                <Link to="/contact" className="cta-button pulse">
                  Start The Conversation <i className="fas fa-arrow-right ms-2"></i>
                </Link>
              </motion.div>
            </motion.div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About; 