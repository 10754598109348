import React, { useState, useEffect } from 'react';
import { collection, getDocs, query, orderBy } from 'firebase/firestore';
import { db } from '../firebase/config';
import { Container, Table, Button, Alert, Modal, Row, Col } from 'react-bootstrap';

const AdminDashboard = () => {
  const [questionnaires, setQuestionnaires] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedQuestionnaire, setSelectedQuestionnaire] = useState(null);
  const [showDetails, setShowDetails] = useState(false);

  useEffect(() => {
    const fetchQuestionnaires = async () => {
      try {
        const q = query(collection(db, 'client_questionnaires'), orderBy('submitted_at', 'desc'));
        const querySnapshot = await getDocs(q);
        const data = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
          submitted_at: doc.data().submitted_at?.toDate().toLocaleString()
        }));
        setQuestionnaires(data);
      } catch (err) {
        console.error('Error fetching questionnaires:', err);
        setError('Failed to load questionnaires');
      } finally {
        setLoading(false);
      }
    };

    fetchQuestionnaires();
  }, []);

  const downloadQuestionnaire = (questionnaire) => {
    const jsonData = JSON.stringify(questionnaire, null, 2);
    const blob = new Blob([jsonData], { type: 'application/json' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `questionnaire-${questionnaire.id}.json`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };

  const handleViewDetails = (questionnaire) => {
    setSelectedQuestionnaire(questionnaire);
    setShowDetails(true);
  };

  const renderDetails = (data) => {
    if (!data) return null;

    return (
      <div>
        <h4 className="mb-4">Contact Information</h4>
        <Row className="mb-3">
          <Col md={6}>
            <p><strong>Name:</strong> {data.contactInfo?.name || 'N/A'}</p>
            <p><strong>Email:</strong> {data.contactInfo?.email || 'N/A'}</p>
            <p><strong>Phone:</strong> {data.contactInfo?.phone || 'N/A'}</p>
          </Col>
        </Row>

        <h4 className="mb-4">Project Details</h4>
        <Row className="mb-3">
          <Col md={6}>
            <p><strong>Project Type:</strong> {data.projectType || 'N/A'}</p>
            <p><strong>Project Description:</strong> {data.projectDescription || 'N/A'}</p>
            <p><strong>Timeline:</strong> {data.timeline || 'N/A'}</p>
          </Col>
        </Row>

        <h4 className="mb-4">Home Page Content</h4>
        <Row className="mb-3">
          <Col md={12}>
            <p><strong>Main Headline:</strong> {data.homePage?.mainHeadline || 'N/A'}</p>
            <p><strong>Sub-headline:</strong> {data.homePage?.subHeadline || 'N/A'}</p>
            <p><strong>Key Features:</strong> {data.homePage?.keyFeatures || 'N/A'}</p>
            <p><strong>Call to Action:</strong> {data.homePage?.callToAction || 'N/A'}</p>
          </Col>
        </Row>

        <h4 className="mb-4">About Page Content</h4>
        <Row className="mb-3">
          <Col md={12}>
            <p><strong>Company Story:</strong> {data.aboutPage?.companyStory || 'N/A'}</p>
            <p><strong>Mission Statement:</strong> {data.aboutPage?.missionStatement || 'N/A'}</p>
            {data.aboutPage?.teamSection === 'yes' && (
              <p><strong>Team Members:</strong> {data.aboutPage?.teamMembers || 'N/A'}</p>
            )}
          </Col>
        </Row>

        <h4 className="mb-4">Services Page Content</h4>
        <Row className="mb-3">
          <Col md={12}>
            <p><strong>Services List:</strong> {data.servicesPage?.servicesList || 'N/A'}</p>
            <p><strong>Process Description:</strong> {data.servicesPage?.processDescription || 'N/A'}</p>
          </Col>
        </Row>

        <h4 className="mb-4">Additional Information</h4>
        <Row className="mb-3">
          <Col md={12}>
            <p><strong>Target Audience:</strong> {data.additionalInfo?.targetAudience || 'N/A'}</p>
            <p><strong>Unique Selling Points:</strong> {data.additionalInfo?.uniqueSellingPoints || 'N/A'}</p>
            <p><strong>Project Goals:</strong> {data.additionalInfo?.projectGoals || 'N/A'}</p>
          </Col>
        </Row>
      </div>
    );
  };

  if (loading) {
    return (
      <Container className="mt-5">
        <Alert variant="info">Loading questionnaires...</Alert>
      </Container>
    );
  }

  if (error) {
    return (
      <Container className="mt-5">
        <Alert variant="danger">{error}</Alert>
      </Container>
    );
  }

  return (
    <Container className="mt-5">
      <h2 className="mb-4">Client Questionnaires</h2>
      <Table striped bordered hover responsive>
        <thead>
          <tr>
            <th>Name</th>
            <th>Email</th>
            <th>Phone</th>
            <th>Project Type</th>
            <th>Submitted</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {questionnaires.map((q) => (
            <tr key={q.id}>
              <td>{q.contactInfo?.name || 'N/A'}</td>
              <td>{q.contactInfo?.email || 'N/A'}</td>
              <td>{q.contactInfo?.phone || 'N/A'}</td>
              <td>{q.projectType || 'N/A'}</td>
              <td>{q.submitted_at || 'N/A'}</td>
              <td>
                <Button 
                  variant="info" 
                  size="sm"
                  className="me-2"
                  onClick={() => handleViewDetails(q)}
                >
                  View
                </Button>
                <Button 
                  variant="primary" 
                  size="sm"
                  onClick={() => downloadQuestionnaire(q)}
                >
                  Download
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      <Modal 
        show={showDetails} 
        onHide={() => setShowDetails(false)}
        size="lg"
        className="text-light"
      >
        <Modal.Header closeButton className="bg-dark border-secondary">
          <Modal.Title>Questionnaire Details</Modal.Title>
        </Modal.Header>
        <Modal.Body className="bg-dark">
          {renderDetails(selectedQuestionnaire)}
        </Modal.Body>
        <Modal.Footer className="bg-dark border-secondary">
          <Button variant="secondary" onClick={() => setShowDetails(false)}>
            Close
          </Button>
          <Button 
            variant="primary" 
            onClick={() => {
              downloadQuestionnaire(selectedQuestionnaire);
              setShowDetails(false);
            }}
          >
            Download
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default AdminDashboard; 