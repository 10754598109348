// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { getAuth } from 'firebase/auth';

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCKZ6DLLlJSqVPCV8HJZTy1XZWX4OU-jJg",
  authDomain: "slydetechconsulting.firebaseapp.com",
  databaseURL: "https://slydetechconsulting-default-rtdb.firebaseio.com",
  projectId: "slydetechconsulting",
  storageBucket: "slydetechconsulting.firebasestorage.app",
  messagingSenderId: "211689982932",
  appId: "1:211689982932:web:62ae8a5478ea41925c2441",
  measurementId: "G-MKLYL7FLFG"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const db = getFirestore(app);
const auth = getAuth(app);

// Remove the emulator connection code since you're not running an emulator
// If you want to use the emulator in the future, you'll need to:
// 1. Install the Firebase CLI
// 2. Run: firebase emulators:start --only firestore
// 3. Then uncomment this code:
/*
if (process.env.NODE_ENV === 'development') {
  try {
    connectFirestoreEmulator(db, 'localhost', 8080);
    console.log('Connected to Firestore emulator');
  } catch (error) {
    console.error('Failed to connect to Firestore emulator:', error);
  }
}
*/

export { app, analytics, db, auth }; 