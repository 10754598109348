import React from 'react';
import { motion } from 'framer-motion';
import PageHeader from '../components/PageHeader';
import { Container, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

const ThankYou = () => {
  const navigate = useNavigate();

  return (
    <div>
      <PageHeader
        title="Thank You!"
        subtitle="Your project details have been saved"
        backgroundImage="url('https://images.unsplash.com/photo-1454165804606-c3d57bc86b40?auto=format&fit=crop&w=1600&q=80')"
      />

      <Container className="mt-5 mb-5 text-center">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          <h2 className="mb-4">Thank You!</h2>
          <p className="lead mb-4">
            Your project details have been successfully saved. We'll continue working on your project as discussed.
          </p>
          <Button 
            variant="primary" 
            size="lg"
            onClick={() => navigate('/')}
          >
            Return to Home
          </Button>
        </motion.div>
      </Container>
    </div>
  );
};

export default ThankYou; 